
//fixed Navbar
$(window).bind('scroll', function() {
	var offset = $('.overview').length?70:0;
	if ($(window).scrollTop() > $('.emptyNavSpace').offset().top - offset) {
		$('#navbar').addClass('fixed-top');
		$('.emptyNavSpace').addClass('expand');
	} else {
		$('#navbar').removeClass('fixed-top');
		$('.emptyNavSpace').removeClass('expand');
	}
});

$(document).ready(function() {
	$('.intro .parallax-slider').parallax({
		imageSrc: stylesheet_directory_uri+"/img/gebaeude2.jpg",
		speed: 0.4,
		naturalWidth: 2560,
		naturalHeight: 1920
	});


	$('.neubauParallax .parallax-slider').parallax({
		imageSrc: stylesheet_directory_uri+"/img/neubau/20190501_110036.jpg",
		speed: 0.4,
		naturalWidth: 2560,
		naturalHeight: 1920
	});
	//mobile ansicht ohne delay
	if($(window).width() < 992) {
		$('.wow').attr('data-wow-delay', '0s');
	}

	new WOW().init();



	//NUMBERS GO UP
	var waypoint = new Waypoint({
		element: document.getElementById('numbersGoUp'),
		handler: function(direction) {
			countUp($(".countUp-1 .wow"), 1500);
			countUp($(".countUp-2 .wow"), 2000);
			countUp($(".countUp-3 .wow"), 2500);
			this.destroy();
		},
		offset: "70%"
	});

	var waypointEvent = new Waypoint({
		element: document.getElementById('eventVid'),
		handler: function() {
			loadVideo();
			this.destroy();
		},
		offset: "150%"
	})


	//NEWS
// 	$(".owl-carousel").owlCarousel({
// 		center: true,
// 		items: 1,
// 		loop: true,
// 		margin: 10,
// 		autoplay: true,
// 		autoplayTimeout: 10,
// 		autoplayHoverPause: true,
// 		//autoHeight: true,
// 		//nav: true,
// 		startPosition: 3
// 	});
});

function goToStart() {
	$('html,body').animate({
		scrollTop: $(".emptyNavSpace").offset().top
	}, 1000);
}

function loadVideo() {
	var video = document.getElementById('eventVid');
	var mp4 = document.getElementById('eventVidSrc');
	d = new Date();
	mp4.src = stylesheet_directory_uri+"/vid/marktgaukler2.mp4";

	video.load();
	video.play();
}
